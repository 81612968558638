<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <template slot="actions">
      <b-dropdown
        v-if="$can('create', 'settings.variables')"
        ref="dropdown"
        aria-role="list"
        position="is-bottom-left"
        :close-on-click="false"
        scrollable
        max-height="400px"
        append-to-body
      >
        <div slot="trigger">
          <b-button
            icon-left="dots-vertical"
            :size="$device.mobile ? 'is-small' : ''"
            type="is-default"
            rounded
          />
        </div>

        <b-dropdown-item
          aria-role="listitem"
          @click="openForm(-1, {}, 'create')"
        >
          <b-icon icon="plus" size="is-small" />
          {{ $t("new_variable") }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <Card v-if="$can('read', 'settings.variables')" content-class="c-table">
      <b-table
        :loading="loading"
        :data="items"
        :total="total"
        hoverable
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :row-class="
          (row, index) =>
            row.id == updated_variable
              ? ' bg-updated'
              : deleted_variable
              ? 'bg-deleted'
              : ''
        "
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template v-if="head.searchable" slot="searchable">
              <template v-if="head.filter_type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  @input="getVars()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.filter_type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  @change="getVars()"
                >
                  <template>
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt[head.filter_id] ? opt[head.filter_id] : opt"
                      :value="opt[head.filter_id] ? opt[head.filter_id] : opt"
                    >
                      {{ opt[head.filter_text] ? opt[head.filter_text] : opt }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  @change="getVars()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  rounded
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getVars"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <template v-if="head.value == 'id'" v-slot="props">
              <a
                v-if="
                  $can('read', 'settings.variables') ||
                  $can('update', 'settings.variables')
                "
                href="javascript:;"
                @click="openForm(props.index, props.row, 'read')"
                >{{ props.row.id }}</a
              >
              <span v-else>{{ props.row.id }}</span>
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                aria-role="list"
                position="is-bottom-left"
                scrollable
                max-height="400px"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="
                    $can('read', 'settings.variables') ||
                    $can('update', 'settings.variables')
                  "
                  aria-role="listitem"
                  @click="openForm(props.index, props.row, 'read')"
                >
                  <b-icon icon="eye" size="is-small" />
                  {{ $t("view") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    props.row.active && $can('delete', 'settings.variables')
                  "
                  aria-role="listitem"
                  @click="confirmDeleting(props.index, props.row)"
                >
                  <b-icon icon="delete" type="is-danger" size="is-small" />
                  {{ $t("delete") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    !props.row.active && $can('delete', 'settings.variables')
                  "
                  aria-role="listitem"
                  @click="restoreVar(props.index, props.row)"
                >
                  <b-icon icon="restore" size="is-small" type="is-success" />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-else v-slot="props">
              <b-icon
                v-if="
                  typeof props.row[head.value] === 'boolean' ||
                  head.filter_type == 'boolean'
                "
                :icon="props.row[head.value] ? 'check' : 'close'"
                :type="props.row[head.value] ? 'is-success' : 'is-danger'"
                custom-size="mdi-18px"
              />
              <template v-else>
                {{ showValue(props.row[head.value]) }}
              </template>
            </template>
          </b-table-column>
        </template>
      </b-table>

      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      updated_variable: null,
      deleted_variable: null,
      perPages: [15, 30, 50],
      perPage: 30,
      total: 0,
      currentPage: 1,
      items: [],
      types: [],
      loading: true,
      searchFocused: false,
      search: "",
      sortingPriority: [],
      singleSorting: {},
      headers: [
        {
          label: this.$t("id"),
          value: "id",
          sortable: true,
          searchable: true,
          width: "80",
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          label: this.$t("type"),
          value: "type",
          sortable: true,
          searchable: true,
          width: "200",
          filter_type: "select",
          filter_id: "id",
          filter_text: "label",
          filter_values: [],
          input: "",
        },
        {
          label: this.$tc("variable", 1),
          value: "var",
          sortable: true,
          searchable: true,
          width: "240",
          input: "",
          filter: "contains",
        },
        {
          label: this.$i18n.t("description"),
          value: "memo",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
        },
        {
          label: this.$t("fo"),
          value: "fo",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          input: "",
        },
        {
          label: this.$tc("system", 2),
          value: "system",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          input: "",
        },
        {
          label: this.$tc("active", 1),
          value: "active",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          centered: true,
          input: "",
        },
        {
          label: this.$i18n.t("actions"),
          value: "actions",
          sortable: false,
          width: "80",
          centered: true,
        },
      ],
    };
  },
  watch: {
    perPage() {
      this.getVars();
    },
    currentPage() {
      this.getVars();
    },
  },
  created() {
    this.updateTitle(this.$i18n.tc("variable", 2));
  },
  mounted() {
    this.sortPressed("id", "asc", { shiftKey: true });

    this.$bus.$on("update-variable", (variable) => {
      let index = this.items.findIndex((v) => v.id == variable.id);

      if (index != -1) {
        this.items.splice(index, 1, variable);

        this.updated_variable = variable.id;
        setTimeout(() => {
          this.updated_variable = null;
        }, 2000);
      }
    });

    this.$bus.$on("add-variable", (variable) => {
      this.items.unshift(variable);

      this.updated_variable = variable.id;
      setTimeout(() => {
        this.updated_variable = null;
      }, 2000);
    });
  },
  destroyed() {
    this.$bus.$off("update-variable");
    this.$bus.$off("add-variable");
  },
  methods: {
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    showValue(value) {
      return value !== undefined && value !== null ? value : "-";
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getVars();
    },
    openForm(index, variable, action) {
      if (
        (this.$can("read", "settings.variables") && index != -1) ||
        (this.$can("update", "settings.variables") && index != -1) ||
        (this.$can("create", "settings.variables") && index == -1)
      ) {
        this.openDrawer("ConfigVarsForm", {
          action: action,
          index: index,
          types: this.types,
          variable: index != -1 ? variable : {},
        });
      }
    },
    confirmDeleting(index, variable) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteVar(index, variable),
      });
    },
    deleteVar(index, variable) {
      this.$axios
        .delete("variables/" + variable.id)
        .then(() => {
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
          this.items.splice(index, 1, { ...variable, active: false });
        })
        .catch((e) => this.clientError(e));
    },
    restoreVar(index, variable) {
      this.$axios
        .patch("variables/" + variable.id + "/restore")
        .then(() => {
          this.items.splice(index, 1, { ...variable, active: true });
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch((e) => this.clientError(e));
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getVars();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getVars();
    },
    getVars() {
      let api_params = `?page=${this.currentPage}&per_page=${this.perPage}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i];
        if (
          ["var", "memo"].includes(value.value) &&
          value.input &&
          value.input.length != 0 &&
          value.input.length < 3
        )
          return;
        else if (value.input || typeof value.input == "boolean") {
          let obj = {
            id: value.value,
            selected:
              value.value == "ends_at" || value.value == "starts_at"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }
      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.loading = false;

      this.$axios.get("/variables" + api_params).then(({ data }) => {
        this.items = data.list;
        let index = this.headers.findIndex((h) => h.value == "type");
        this.headers[index].filter_values = data.filters.types;
        this.types = data.filters.types;
        this.total = data.pagination.total;
        this.loading = false;
      });
    },
  },
};
</script>
